<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M1.354 20.354l7.772-7.773a.5.5 0 0 0-.707-.707L.646 19.646a.5.5 0 0 0 .708.708zM13.59 14.818v-1.727a.5.5 0 1 0-1 0v1.727a.5.5 0 1 0 1 0zM13.59 2.727V1a.5.5 0 1 0-1 0v1.727a.5.5 0 1 0 1 0zM6.182 8.41h1.727a.5.5 0 0 0 0-1H6.182a.5.5 0 1 0 0 1zM18.273 8.41H20a.5.5 0 1 0 0-1h-1.727a.5.5 0 1 0 0 1zM17.763 4.808l1.727-1.727a.5.5 0 1 0-.707-.707L17.056 4.1a.5.5 0 0 0 .707.707zM7.556 3.08l1.727 1.728a.5.5 0 0 0 .707-.707L8.263 2.374a.5.5 0 1 0-.707.707zM17.056 12.58l1.727 1.728a.5.5 0 1 0 .707-.707l-1.727-1.727a.5.5 0 0 0-.707.707z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
